import axios from "axios";

const phoneClient = axios.create({
  baseURL: "https://smsservice-mrdiy-linux.azurewebsites.net/otp",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const getOTP = async ({ mobileNumber, recaptchaToken }) => {
  const { data } = await phoneClient.post("/request", {
    username: mobileNumber,
    token: recaptchaToken,
  });

  return data;
};

const verifyOTP = async ({ mobileNumber, code }) => {
  const { data } = await phoneClient.post("/verify", {
    username: mobileNumber,
    code,
  });

  return data;
};

export default {
  getOTP,
  verifyOTP,
};
